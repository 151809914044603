<template>

	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" :close-on-click-modal="false" :close-on-press-escape="false"
			@closed="onclosed">
			<div class="form_box">

				<div class="form_flex">
					<div class="lefts">
						调动人员姓名：
					</div>
					<div class="rights">
						<el-select filterable :filter-method="do_search_user" v-model="form.name" placeholder="请选择人员_姓名"
							@change="chanhe_user">
							<el-option v-for="item in user_list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="form_flex2" style="justify-content: space-between;">
					<div class="form_flex_text">
						<div class="form_flex_text_left">
							调动前部门：
						</div>
						<span>{{form.q_bm_title}}</span>
					</div>

					<div class="form_flex_text" style="width: 300px;">
						<div class="form_flex_text_left">
							调动前岗位：
						</div>
						<span>{{form.q_gw_title}}</span>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						调令号：
					</div>
					<div class="rights" style="padding-right: 10px;">
						<el-input v-model="form.order_no" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						调动后部门：
					</div>
					<div class="rights">
						<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" v-model="gw_list_ac"
							clearable @change="change_gw"></el-cascader>

					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						调动后岗位：
					</div>
					<div class="rights">

						<el-select clearable v-model="form.h_gw_title" placeholder="请选择">
							<el-option v-for="(item, index) in gw_list2" :key="index" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						报道日期：
					</div>
					<div class="rights">
						<!-- <el-input v-model="form.cardId" placeholder="请输入"></el-input> -->
						<el-date-picker v-model="form.bd_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						下发时间：
					</div>
					<div class="rights">
						<!-- <el-input v-model="form.cardId" placeholder="请输入"></el-input> -->
						<el-date-picker v-model="form.xf_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						上传调令文件：
					</div>
					<div class="rights" style="border: none;">
						<!-- <el-input v-model="form.cardId" placeholder="请输入"></el-input> -->
						<div class="shangchuan">
							<button class="btn-upload" @click="handle_up"><img class="image"
									src="../../../src/assets/img/passport/upload.png" alt="" />上传</button>
							<span class="text">格式为JPG、RAR、PDF、Word</span>
						</div>
					</div>
				</div>
				<div class="form_flex2" v-if="form.pdf.name ">
					<div class="form_flex_text" style="margin-right: 100px;">
						<div class="form_flex_text_left">
						</div>
						<span style="margin: 0;">
							{{ form.pdf.name }}
							<img class="image" src="../../../src/assets/img/passport/close.png" alt="" @click='form.pdf={}' />
						</span>
					</div>

				</div>






				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>
		<upload_img ref="upload_img"></upload_img>

	</div>
</template>
<script>
	import upload_img from '@/components/upload/upload.vue'

	export default {
		name: "Customer_Information_form",
		components: {
			upload_img,
		},
		props: {},
		data() {
			return {



				modal_title: '新增调令',
				shows: false,
				form: {
					id: 0, //修改id
					user_id: '', //用户id
					name: '', //用户名称
					q_bm_title: '', //调前部门
					q_gw_title: '', //调前岗位
					order_no: '', //调令号
					h_bm_title: '', //调后部门
					h_gw_title: '', //调后岗位
					xf_time: '', //下发时间
					bd_time: '', //报道时间
					pdf: {}, //调令文件
					bm_id_list: ''
				},

				user_list: [],
				user_select: '',
				type_list: [],
				type_select: '',
				fileList: [],
				gw_list: [], //部门
				gw_list_is_gw: [], //所有的岗位
				gw_list2: [], //岗位
				gw_list_ac: [], //部门选中
				props: {
					multiple: false,
					value: 'id',
					label: 'title'
				},
			};
		},
		methods: {
			onclosed() {
				this.form = {
					id: 0, //修改id
					user_id: '', //用户id
					name: '', //用户名称
					q_bm_title: '', //调前部门
					q_gw_title: '', //调前岗位
					order_no: '', //调令号
					h_bm_title: '', //调后部门
					h_gw_title: '', //调后岗位
					xf_time: '', //下发时间
					bd_time: '', //报道时间
					pdf: {}, //调令文件
					bm_id_list: ''
				}
				this.gw_list2 = []
				this.gw_list_ac = []
			},
			init(row) {
				this.shows = true
				this.modal_title = '新增调令'
				if (row) {
					this.modal_title = '编辑调令'
					this.form = JSON.parse(JSON.stringify(row))
					this.form.pdf = JSON.parse(this.form.pdf)
					this.gw_list_ac = JSON.parse(this.form.bm_id_list)
				}
			},
			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			//确认添加修改
			handle_ends() {
				if (!this.form.name) {
					alertErr('请选择人员_姓名')
					return
				}
				if (!this.form.order_no) {
					alertErr('请输入调令号')
					return
				}
				if (!this.form.h_bm_title) {
					alertErr('请选择调动后部门')
					return
				}
				if (!this.form.h_gw_title) {
					alertErr('请选择调动后岗位')
					return
				}
				if (!this.form.xf_time) {
					alertErr('请选择报道日期')
					return
				}
				if (!this.form.bd_time) {
					alertErr('请选择下发时间')
					return
				}
				if (!this.form.pdf.name) {
					alertErr('请上传调令文件')
					return
				}
				this.form.pdf = JSON.stringify(this.form.pdf)
				this.form.bm_id_list = JSON.stringify(this.form.bm_id_list)
				this.loading = true
				this.$api("editUserRedeploy", {
					...this.form
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						this.$emit("handle_get_list");

					} else {
						alertErr(res.msg)
					}
				});
			},




			//获取岗位
			get_gw_list() {
				this.loading = true
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						let level = 0
						let list = []
						// let data1=res.data
						let data1 = []
						res.data.forEach((e) => {
							if (e.is_gw != 1) {
								data1.push(e)
							}
							this.gw_list_is_gw.push(e)

						})
						let data = data1
						data.forEach((e, i) => {
							if (e.level > level) {
								level = e.level
							}
							this.$set(e, 'children', [])
						})
						console.log('最高登记', level)

						for (let i = 1; i <= level; i++) {
							let level_list = []
							data.forEach((e) => {
								if (e.level == i) {
									level_list.push(e)
								}
							})
							list.push(level_list)
						}
						console.log('列表', list)
						list[list.length - 1].forEach((e) => {
							this.$delete(e, 'children')
						})
						// 总列表  最后一位的下标
						this.gw_list_data(list, list.length - 1)



					} else {
						alertErr(res.msg)
					}
				});
			},
			//处理岗位列表
			gw_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e) => {
					if (e.children.length == 0) {
						this.$delete(e, 'children')
					}
				})
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.gw_list = list[0]
					return
				}
				this.gw_list_data(list, index2)
			},
			chanhe_user(e) {
				console.log(e)
				this.user_list.forEach((v) => {
					if (e == v.id) {
						this.form.name = v.name
						this.form.user_id = v.id
						this.form.q_bm_title = v.depart_title
						this.form.q_gw_title = v.level6
					}
				})
			},
			do_search_user(value) {
				console.log('搜索用户 value', value)
				this.$api("getUserList", {
					keyword: value
				}, "get").then((res) => {
					this.$log('搜索用户', res)
					this.user_list = res.data.list || []
				});
			},
			change_gw(e) {
				let title_list = []
				let gw_list = []
				this.form.h_gw_title = ''
				this.form.h_bm_title = ''
				this.form.bm_id_list = e
				e.forEach((v) => {
					this.gw_list_is_gw.forEach((v2) => {
						if (v2.id == v) {
							title_list.push(v2.title)
						}
					})
				})
				this.gw_list_is_gw.forEach((v) => {
					if (v.parent_id == e[e.length - 1]) {
						gw_list.push(v)
					}
				})
				console.log(title_list)
				title_list.forEach((v, i) => {
					let text = ''
					if (i != 0) {
						text = '/'
					}
					this.form.h_bm_title = this.form.h_bm_title + text + v
				})
				console.log(gw_list)
				this.gw_list2 = gw_list

			},

			handle_up() {
				this.$refs.upload_img.uploadFile()
			},
			upload_url(url) {
				console.log(url)
				this.form.pdf = url
			},


		},
		computed: {
			uploadActionImport() {
				let ret =
					process.env.NODE_ENV !== "production" ?
					"/api/uploadImage" :
					this.uploadPrefix + "/api/uploadImage";

				return ret;
			},

			uploadExtraData() {
				return {
					// user_id: "xxx",
					type: 1,
				};
			},

		},

		watch: {

		},

		created() {
			this.get_gw_list()
			this.do_search_user()

		},


	};
</script>

<style scoped lang="less">
	.btn-upload {
		display: flex;
		align-items: center;
		width: 88px;
		height: 28px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #2373C8;
		text-align: center;
		line-height: 28rpx;
		font-weight: 400;
		font-size: 14px;
		color: #2373C8;

		.image {
			margin-left: 20px;
			margin-right: 10px;
			width: 13px;
			height: 13px;
		}
	}

	.shangchuan {
		display: flex;
		align-items: center;
	}

	.text {
		margin-left: 24px;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
	}


	.custom-modal-wrap {

		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_flex2 {
			display: flex;
			align-items: center;
			// justify-content: space-around;
			margin-bottom: 16px;

			.form_flex_text {

				.form_flex_text_left {
					width: 120px;
					text-align: right;
					// /deep/
				}

				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 14px;
				color: #4A4A4A;

				span {
					margin-left: 15px;
					display: flex;
					align-items: center;

					img {
						width: 12px;
						margin-left: 60px;
						height: 12px;
						cursor: pointer;
					}
				}
			}
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				align-items: flex-start;
				margin-bottom: 16px;



				.lefts {
					text-align: right;
					line-height: 40px;
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&:not(.no-required)::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: 1px solid #D4D4D4;
					flex: 1;
					height: 40px;
					height: auto;
					background: #fff;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

						.el-input__inner {
							border: 0;
						}
					}

					.el-select {
						width: 100%;
					}

					/deep/.el-cascader {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>